import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const CRUD_LOAN = "[LOAN] CRUD LOAN"

export const GET_LOANS_CUSTOMERS = "[LOAN] GET LOANS CUSTOMERS"

export const GET_LOANS_BY_CUSTOMER = "[LOAN] GET LOANS BY CUSTOMER"

export const CRUD_PAYMENT_DUE = "[PAYMENTS DUES] CRUD PAYMENT DUE"

export const GET_PAYMENTS_LOANS_BY_CUSTOMER = "[PAYMENTS LOANS] GET PAYMENTS LOANS BY CUSTOMER"

export const GET_CUSTOMER_LOANS_REPORT = "[LOANS REPORT] GET CUSTOMER LOANS REPORT"

export function saveLoan(form) {
  const request = axios.post(`${API_URL}/api/rprestamo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar el prestamo")
      console.log(error)
    })
  }
}

export function updateDateLoanDue(form) {
  const request = axios.post(`${API_URL}/api/prestamo-cuota/actualizar-fecha`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo actualizar la fecha de la cuota")
      console.log(error)
    })
  }
}

export function deleteLoan(loanId) {
  const request = axios.post(`${API_URL}/api/eprestamo?idprestamo=${loanId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.error("Error inesperado. No se pudo anular el prestamo")
      dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getLoansCustomers() {
  const request = axios.get(`${API_URL}/api/prestamosclientes`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_LOANS_CUSTOMERS, payload: response.data.detalles })
    })
  }
}

export function getLoansByCustomer(customerId) {
  const request = axios.get(`${API_URL}/api/prestamoscliente?idpersona=${customerId}`)
  return (dispatch) => {
    dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      toast.error("Error inesperado. No se pudo obtener los prestamos del cliente")
      dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      console.log(error)
    })
  }
}

export function savePaymentDue(form) {
  const request = axios.post(`${API_URL}/api/rpagocuotaprestamo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar el pago")
      console.log(error)
    })
  }
}

export function savePaymentLoanAmortization(form) {
  const request = axios.post(`${API_URL}/api/rpagoamortizacionprestamo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar el pago")
      console.log(error)
    })
  }
}

export function deletePaymentDue(paymentId) {
  const request = axios.post(`${API_URL}/api/epagocuotaprestamo?idpago=${paymentId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo anular el pago")
      console.log(error)
    })
  }
}

export function getPaymentsLoansByCustomer(customerId) {
  const request = axios.get(`${API_URL}/api/gpagoscuotasprestamos?idpersona=${customerId}`)
  return (dispatch) => {
    dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      toast.error("Error inesperado. No se pudo obtener los pagos del cliente")
      console.log(error)
    })
  }
}

export function getCustomerLoansReport({ customerId = 0 }) {
  const request = axios.get(`${API_URL}/api/greporteprestamoscliente?idpersona=${customerId}`)
  return (dispatch) => {
    dispatch({ type: GET_CUSTOMER_LOANS_REPORT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_CUSTOMER_LOANS_REPORT, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_CUSTOMER_LOANS_REPORT, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_CUSTOMER_LOANS_REPORT, payload: { loading: false, data: null } })
      toast.error("Error inesperado. No se pudo obtener el reporte de prestamos del cliente")
      console.log(error)
    })
  }
}