import * as Actions from "../../actions/app"

const initialState = {

  loading_establishment: false,
  establishment: null,

  loading_crud_establishment: false,
  crud_establishment: false,

  loading_branches: false,
  branches: null,

  loading_crud_branch: false,
  crud_branch: false,

  loading_options_by_branch: false,
  options_by_branch: null,

  loading_crud_branch_option: false,
  crud_branch_option: false,

  loading_bank_accounts: false,
  bank_accounts: null,

  loading_crud_bank_account: false,
  crud_bank_account: false,
}

const establishment = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ESTABLISHMENT: {
      return {
        ...state,
        loading_establishment: action.payload.loading,
        establishment: action.payload.data ?? state.establishment,
      }
    }

    case Actions.CRUD_ESTABLISHMENT: {
      return {
        ...state,
        loading_crud_establishment: action.payload.loading,
        crud_establishment: action.payload.crud,
      }
    }

    case Actions.GET_BRANCHES: {
      return {
        ...state,
        loading_branches: action.payload.loading,
        branches: action.payload.data ?? state.branches,
      }
    }

    case Actions.CRUD_BRANCH: {
      return {
        ...state,
        loading_crud_branch: action.payload.loading,
        crud_branch: action.payload.crud,
      }
    }

    case Actions.GET_OPTIONS_BY_BRANCH: {
      return {
        loading_options_by_branch: action.payload.loading,
        options_by_branch: action.payload.data,
      }
    }

    case Actions.CRUD_BRANCH_OPTION: {
      return {
        ...state,
        loading_crud_branch_option: action.payload.loading,
        crud_branch_option: action.payload.crud,
      }
    }

    case Actions.GET_BANK_ACCOUNTS: {
      return {
        ...state,
        loading_bank_accounts: action.payload.loading,
        bank_accounts: action.payload.data ?? state.bank_accounts,
      }
    }

    case Actions.CRUD_BANK_ACCOUNT: {
      return {
        ...state,
        loading_crud_bank_account: action.payload.loading,
        crud_bank_account: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}
export default establishment
