import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const GET_ALL_VOUCHERS = "[VOUCHERS] GET ALL VOUCHERS"

export const GET_DEFAULT_VOUCHER = "[VOUCHERS] GET DEFAULT VOUCHER"

export const CRUD_VOUCHER = "[VOUCHERS] CRUD VOUCHER"

export const GET_VOUCHERS_FOR_SALE = "[VOUCHERS] GET VOUCHERS FOR SALE"

export function getAllVouchers() {
  const request = axios.get(`${API_URL}/api/comprobante`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_ALL_VOUCHERS, payload: response.data.detalles })
      return
    }).catch((err) => {
      dispatch({ type: GET_ALL_VOUCHERS, payload: [] })
      toast.error("Error inesperado. No se pudo obtener los comprobantes")
      console.log(err)
    })
}

export function getDefaultVoucher() {
  const request = axios.get(`${API_URL}/api/comprobante-por-defecto`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_DEFAULT_VOUCHER, payload: response.data.detalles })
    }).catch((error) => {
      dispatch({ type: GET_DEFAULT_VOUCHER, payload: null })
      toast.info("No se pudo consultar un comprobante predeterminado")
      console.log(error)
    })
  }
}

export function saveVoucher(from) {
  const request = axios.post(`${API_URL}/api/comprobante-guardar`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_VOUCHER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar el comprobante")
      console.log(err)
    })
  }
}

export function updateVoucher(from) {
  const request = axios.post(`${API_URL}/api/comprobante-actualizar`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_VOUCHER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo actualizar el comprobante")
      console.log(err)
    })
  }
}

export function deleteVoucher(from) {
  const request = axios.post(`${API_URL}/api/comprobante-eliminar`, from)
  return (dispatch) => {
    toast.info("Borrando comprobante...", { toastId: "deleteVoucher", autoClose: false })
    dispatch({ type: CRUD_VOUCHER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: "deleteVoucher", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "deleteVoucher", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo borrar el comprobante", { toastId: "deleteVoucher", autoClose: 5000 })
      console.log(err)
    })
  }
}

export function updateVoucherStatus(form) {
  const request = axios.post(`${API_URL}/api/comprobante-cambiar-estado`, form)
  return (dispatch) => {
    toast.info("Actualizando estado...", { toastId: "updateVoucherStatus", autoClose: false })
    dispatch({ type: CRUD_VOUCHER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: "updateVoucherStatus", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "updateVoucherStatus", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_VOUCHER, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo actualizar el estado del comprobante", { toastId: "updateVoucherStatus", autoClose: 5000 })
      console.log(err)
    })
  }
}

export function getVouchersForSale(user = {}) {
  const request = axios.post(`${API_URL}/api/comprobante-para-facturacion`, user)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_VOUCHERS_FOR_SALE, payload: response.data.detalles })
      return
    }).catch((err) => {
      dispatch({ type: GET_VOUCHERS_FOR_SALE, payload: [] })
      toast.error("Error inesperado. No se pudo obtener los comprobantes de facturacion del usuario")
      console.log(err)
    })
}