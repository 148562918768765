import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const GET_ALL_OPERATING_EXPENSES = "[OPERATING EXPENSES] GET ALL OPERATING EXPENSES"

export const CRUD_OPERATING_EXPENSES = "[OPERATING EXPENSES] CRUD OPERATING EXPENSES"

export const GET_UTILITY_OPERATING_EXPENSES = "[OPERATING EXPENSES] GET UTILITY OPERATING EXPENSES"

export const GET_SALES_DATA_HISTOGRAM_YEAR = "[OPERATING EXPENSES] GET SALES DATA HISTOGRAM YEAR"

export const CRUD_EXPENSES_OF_MONTH = "[OPERATING EXPENSES] CRUD_EXPENSES OF MONTH"

export const GET_MONTH_HISTORY_HISTOGRAM_YEAR = "[OPERATING EXPENSES] GET MONTH HISTORY HISTOGRAM YEAR"

export function getAllOperatingExpenses() {
  const request = axios.get(`${API_URL}/api/gastosoperativos`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_ALL_OPERATING_EXPENSES, payload: response.data.detalles })
    })
  }
}

export function saveOperatingExpense(form) {
  const request = axios.post(`${API_URL}/api/rgastosoperativos`, form)
  return (dispatch) => {
    toast.info("Guardando registro de gasto...", { toastId: "saveOperatingExpense", autoClose: false })
    dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: true, crud: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        toast.success(response.data.mensaje, { toastId: "saveOperatingExpense", autoClose: 5000 })
        dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { toastId: "saveOperatingExpense", autoClose: 5000 })
        dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: false } })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.error("Error al registrar gasto", { toastId: "saveOperatingExpense", autoClose: 5000 })
      console.log(CRUD_OPERATING_EXPENSES, error)
      dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: false } })
    })
  }
}

export function deleteOperatingExpense(operatingExpenseId) {
  const request = axios.get(`${API_URL}/api/egastosoperativos/${operatingExpenseId}`)
  return (dispatch) => {
    toast.info("Eliminando registro del gasto...", { toastId: "deleteOperatingExpense", autoClose: false })
    dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: true, crud: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        toast.success(response.data.mensaje, { toastId: "deleteOperatingExpense", autoClose: 5000 })
        dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { toastId: "deleteOperatingExpense", autoClose: 5000 })
        dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: false } })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.error("Error al registrar gasto", { toastId: "deleteOperatingExpense", autoClose: 5000 })
      console.log(CRUD_OPERATING_EXPENSES, error)
      dispatch({ type: CRUD_OPERATING_EXPENSES, payload: { loading: false, crud: false } })
    })
  }
}

export function getUtilityOperatingExpenses(begin = '', end = '', userId = '') {
  const request = axios.get(
    `${API_URL}/api/gutilidadproductos?inicio=${begin}&fin=${end}&idusuario=${userId}`
  )
  return (dispatch) => {
    dispatch({ type: GET_UTILITY_OPERATING_EXPENSES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_UTILITY_OPERATING_EXPENSES, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_UTILITY_OPERATING_EXPENSES, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_UTILITY_OPERATING_EXPENSES, error)
      dispatch({ type: GET_UTILITY_OPERATING_EXPENSES, payload: { loading: false, data: null } })
    })
  }
}

export function getSalesDataToHistogramByYear(year = '') {
  const request = axios.get(`${API_URL}/api/gventasmensualhistograma?anio=${year}`)
  return (dispatch) => {
    dispatch({ type: GET_SALES_DATA_HISTOGRAM_YEAR, payload: { loading: true, data: null } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALES_DATA_HISTOGRAM_YEAR, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_SALES_DATA_HISTOGRAM_YEAR, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_SALES_DATA_HISTOGRAM_YEAR, error)
      dispatch({ type: GET_SALES_DATA_HISTOGRAM_YEAR, payload: { loading: false, data: null } })
    })
  }
}

export function saveSalesHistory(form) {
  const request = axios.post(`${API_URL}/api/rhistoricoventas`, form)
  return (dispatch) => {
    toast.info("Guardando registros del mes...", { toastId: "saveSalesHistory", autoClose: false })
    dispatch({ type: CRUD_EXPENSES_OF_MONTH, payload: { loading: true, crud: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        toast.success(response.data.mensaje, { toastId: "saveSalesHistory", autoClose: 5000 })
        dispatch({ type: CRUD_EXPENSES_OF_MONTH, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { toastId: "saveSalesHistory", autoClose: 5000 })
        dispatch({ type: CRUD_EXPENSES_OF_MONTH, payload: { loading: false, crud: false } })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_EXPENSES_OF_MONTH, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.error("Error al guardar registros del mes", { toastId: "saveSalesHistory", autoClose: 5000 })
      console.log(CRUD_EXPENSES_OF_MONTH, error)
      dispatch({ type: CRUD_EXPENSES_OF_MONTH, payload: { loading: false, crud: false } })
    })
  }
}

export function getMonthHistoryToHistogramByYear(year = '') {
  const request = axios.get(`${API_URL}/api/ghistoricomensualhistograma?anio=${year}`)
  return (dispatch) => {
    dispatch({ type: GET_MONTH_HISTORY_HISTOGRAM_YEAR, payload: { loading: true, data: null } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_MONTH_HISTORY_HISTOGRAM_YEAR, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_MONTH_HISTORY_HISTOGRAM_YEAR, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_MONTH_HISTORY_HISTOGRAM_YEAR, error)
      dispatch({ type: GET_MONTH_HISTORY_HISTOGRAM_YEAR, payload: { loading: false, data: null } })
    })
  }
}
