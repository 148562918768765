import React from 'react'
import { AuthRoles } from "components/auth"

export const SalesBySellerConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/sales_by_seller`,
      component: React.lazy(() => import('./SalesBySeller'))
    }
  ]
}