import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const GET_CASH_RECEIPTS = "[CASH MOVEMENTS] GET CASH RECEIPTS"

export const GET_CASH_OUTLAYS = "[CASH MOVEMENTS] GET CASH OUTLAYS"

export const CRUD_CASH_MOVEMENTS = "[CASH MOVEMENTS] CRUD CASH MOVEMENTS"

export function getCashReceipts({ page = 1, pageSize = 10, startDate = '', endDate = '', search = '', skipUser = false }) {
  const request = axios.get(`${API_URL}/api/caja-chica/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      tipo_registro: 'I',
      busqueda: search,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_CASH_RECEIPTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_CASH_RECEIPTS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_CASH_RECEIPTS, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function getCashOutlays({ page = 1, pageSize = 10, startDate = '', endDate = '', search = '', skipUser = false }) {
  const request = axios.get(`${API_URL}/api/caja-chica/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      tipo_registro: 'E',
      busqueda: search,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_CASH_OUTLAYS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_CASH_OUTLAYS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_CASH_OUTLAYS, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function saveCashMovement(from) {
  const request = axios.post(`${API_URL}/api/caja-chica/registrar-caja-chica`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: true, crud: false } })
    toast.info('Guardando recibo...', { toastId: 'saveCashMovement', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        toast.update('saveCashMovement', { render: response.data.mensaje, type: "error", autoClose: 5000 })
        dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: false } })
        return
      }

      dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: true } })
      toast.update('saveCashMovement', { render: response.data.mensaje, type: "success", autoClose: 5000 })

      return setTimeout(() => {
        dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      toast.update('saveCashMovement', { render: "Error al guardar el recibo...", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteCashMovement(form) {
  const request = axios.post(`${API_URL}/api/caja-chica/eliminar-caja-chica`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: true, crud: false } })
    toast.info('Guardando recibo...', { toastId: 'deleteCashMovement', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        toast.update('deleteCashMovement', { render: response.data.mensaje, type: "error", autoClose: 5000 })
        dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: false } })
        return
      }

      dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: true } })
      toast.update('deleteCashMovement', { render: response.data.mensaje, type: "success", autoClose: 5000 })

      return setTimeout(() => {
        dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      toast.update('deleteCashMovement', { render: "Error al eliminar el recibo...", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_CASH_MOVEMENTS, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}