import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const CRUD_ESTABLISHMENT_COMPLAINT = "[COMPLAINTS BOOK] CRUD ESTABLISHMENT COMPLAINT"

export const GET_ESTABLISHMENTS_COMPLAINTS = "[COMPLAINTS BOOK] GET ESTABLISHMENTS COMPLAINTS"

export const CRUD_USER_ESTABLISHMENT_COMPLAINT = "[COMPLAINTS BOOK] CRUD USER ESTABLISHMENT COMPLAINT"

export const GET_USERS_ESTABLISHMENT_COMPLAINT = "[COMPLAINTS BOOK] GET USERS ESTABLISHMENT COMPLAINT"

export const CRUD_COMPLAINT = "[COMPLAINTS BOOK] CRUD COMPLAINT"

export const GET_COMPLAINTS = "[COMPLAINTS BOOK] GET COMPLAINTS"

export function saveEstablishmentComplaint(form) {
  const request = axios.post(`${API_URL}/api/establecimientoreclamo/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: true } })
    toast.info('Registrando reclamación...', { toastId: 'saveEstablishmentComplaint', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: true } })
        toast.update('saveEstablishmentComplaint', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveEstablishmentComplaint', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('saveEstablishmentComplaint', { render: "Error inesperado. No se pudo registrar el establecimiento", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteEstablishmentComplaint(form) {
  const request = axios.post(`${API_URL}/api/establecimientoreclamo/eliminar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: true } })
    toast.info('Registrando reclamación...', { toastId: 'deleteEstablishmentComplaint', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: true } })
        toast.update('deleteEstablishmentComplaint', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteEstablishmentComplaint', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('deleteEstablishmentComplaint', { render: "Error inesperado. No se pudo eliminar el establecimiento", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function saveUserEstablishmentComplaint(form) {
  const request = axios.post(`${API_URL}/api/establecimientoreclamo/usuarios/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: true } })
    toast.info('Registrando reclamación...', { toastId: 'saveUserEstablishmentComplaint', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: true } })
        toast.update('saveUserEstablishmentComplaint', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveUserEstablishmentComplaint', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('saveUserEstablishmentComplaint', { render: "Error inesperado. No se pudo registrar al usuario en el establecimiento", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteUserEstablishmentComplaint(form) {
  const request = axios.post(`${API_URL}/api/establecimientoreclamo/usuarios/eliminar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: true } })
    toast.info('Registrando reclamación...', { toastId: 'deleteUserEstablishmentComplaint', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: true } })
        toast.update('deleteUserEstablishmentComplaint', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteUserEstablishmentComplaint', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('deleteUserEstablishmentComplaint', { render: "Error inesperado. No se pudo eliminar al usuario del establecimiento", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_USER_ESTABLISHMENT_COMPLAINT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getUsersEstablishmentComplaint({ establishmentComplaintId = 0 }) {
  const request = axios.get(
    `${API_URL}/api/establecimientoreclamo/usuarios?idestablecimiento=${establishmentComplaintId}`
  )
  return (dispatch) => {
    dispatch({ type: GET_USERS_ESTABLISHMENT_COMPLAINT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_USERS_ESTABLISHMENT_COMPLAINT, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_USERS_ESTABLISHMENT_COMPLAINT, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      toast.error("Error inesperado. No se pudo obtener los usuarios del establecimiento")
      dispatch({ type: GET_USERS_ESTABLISHMENT_COMPLAINT, payload: { loading: false, data: null } })
      console.log(error)
    })
  }
}

export function getEstablishmentsComplaints() {
  const request = axios.get(`${API_URL}/api/establecimientoreclamo`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ESTABLISHMENTS_COMPLAINTS, payload: response.data.detalles })
    }).catch((error) => {
      toast.error("Error al obtener los establecimientos")
      console.log(error);
    })
  }
}

export function saveComplaint(form) {
  const request = axios.post(`${API_URL}/api/reclamacion/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_COMPLAINT, payload: { loading: true } })
    toast.info('Registrando reclamación...', { toastId: 'saveComplaintBook', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_COMPLAINT, payload: { loading: false, crud: true } })
        toast.update('saveComplaintBook', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveComplaintBook', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_COMPLAINT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('saveComplaintBook', { render: "Error inesperado. No se pudo registrar la reclamación", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_COMPLAINT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getComplaints({ establishmentComplaintId = 0, state = 1 }) {
  const request = axios.get(
    `${API_URL}/api/reclamacion?idestablecimiento=${establishmentComplaintId}&estado=${state}`
  )
  return (dispatch) => {
    dispatch({ type: GET_COMPLAINTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_COMPLAINTS, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_COMPLAINTS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      toast.error("Error inesperado. No se pudo obtener las reclamaciones")
      dispatch({ type: GET_COMPLAINTS, payload: { loading: false, data: null } })
      console.log(error)
    })
  }
}

export function responseComplaint(form) {
  const request = axios.post(`${API_URL}/api/reclamacion/responder`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_COMPLAINT, payload: { loading: true } })
    toast.info(
      form.estado == 0 ? 'Aceptando reclamación...' : 'Rechazando reclamación...',
      { toastId: 'responseComplaint', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_COMPLAINT, payload: { loading: false, crud: true } })
        toast.update('responseComplaint', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('responseComplaint', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_COMPLAINT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('responseComplaint',
        {
          render: `Error inesperado. No se pudo ${form.estado == 1 ? 'aceptar' : 'rechazar'} la reclamación`,
          type: "error",
          autoClose: 5000
        }
      )
      dispatch({ type: CRUD_COMPLAINT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}
