import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const GET_ALL_VEHICLE_STATIONS = "[VEHICLE_STATIONS] GET ALL VEHICLE STATIONS"
export const LOADING_CRUD_VEHICLE_STATION = "[VEHICLE_STATIONS] LOADING CRUD VEHICLE STATION"
export const CRUD_VEHICLE_STATION = "[VEHICLE_STATIONS] CRUD VEHICLE STATION"

export function getAllVehicleStations() {
  const request = axios.get(`${API_URL}/api/estacion`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_VEHICLE_STATIONS, payload: response.data.detalles })
    })
}

export function saveVehicleStation(form) {
  const request = axios.post(`${API_URL}/api/restacion`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: true } })
    toast.info("Guardando estación...", { toastId: "saveVehicleStation", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: true } })
        toast.update("saveVehicleStation", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveVehicleStation", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      toast.update("saveVehicleStation", { autoClose: 4000, type: "error", render: "Error al guardar la estación" })
      console.log(error)
    })
  }
}

export function updateVehicleStation(form) {
  const request = axios.post(`${API_URL}/api/aestacion`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: true } })
    toast.info("Actualizando estación...", { toastId: "updateVehicleStation", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: true } })
        toast.update("updateVehicleStation", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateVehicleStation", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      toast.update("updateVehicleStation", { autoClose: 4000, type: "error", render: "Error al actualizar la estación" })
      console.log(error)
    })
  }
}

export function updateStatusVehicleStation(form) {
  const request = axios.post(`${API_URL}/api/cestacion`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: true } })
    toast.info("Actualizando estado...", { toastId: "updateStatusVehicleStation", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: true } })
        toast.update("updateStatusVehicleStation", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateStatusVehicleStation", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      toast.update("updateStatusVehicleStation", { autoClose: 4000, type: "error", render: "Error al actualizar el estado" })
      console.log(error)
    })
  }
}

export function deleteVehicleStation(form) {
  const request = axios.post(`${API_URL}/api/eestacion`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: true } })
    toast.info("Eliminando estación...", { toastId: "deleteVehicleStation", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: true } })
        toast.update("deleteVehicleStation", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicleStation", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_STATION, payload: { loading: false, crud: false } })
      toast.update("deleteVehicleStation", { autoClose: 4000, type: "error", render: "Error al eliminar la estación" })
      console.log(error)
    })
  }
}
