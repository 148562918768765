import axios from 'axios'
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'
import { API_URL } from 'constants/index'

export const GET_INVENTORY_PRODUCTS = '[INVENTORY PRODUCTS] GET INVENTORY PRODUCTS'

export const CRUD_INVENTORY_PRODUCT = '[INVENTORY PRODUCTS] CRUD INVENTORY PRODUCT'

export function getInventoryProducts({ page = 1, pageSize = 10, storeId = 0, status = '', search = '' }) {
  const request = axios.get(
    `${API_URL}/api/inventario-producto/productos`, {
    params: {
      page,
      por_pagina: pageSize,
      almacen_id: storeId,
      estado: status,
      busqueda: search
    }
  })
  return dispatch => {
    dispatch({ type: GET_INVENTORY_PRODUCTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_INVENTORY_PRODUCTS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_INVENTORY_PRODUCTS, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function saveInventoryProduct(form) {
  const request = axios.post(
    `${API_URL}/api/inventario-producto/guardar`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_INVENTORY_PRODUCT, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_INVENTORY_PRODUCT,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje)
        } else {
          dispatch({
            type: CRUD_INVENTORY_PRODUCT,
            payload: { loading: false, crud: false },
          })
          toast.error(response.data.mensaje)
        }

        dispatch({
          type: CRUD_INVENTORY_PRODUCT,
          payload: { loading: false, crud: false },
        })
      })
      .catch((error) => {
        dispatch({
          type: CRUD_INVENTORY_PRODUCT,
          payload: { loading: false, crud: false },
        })
        toast.error('Error inesperado. No se pudo procesar la solicitud')
        console.log(error)
      })
  }
}

export function synchronizeInventoryProduct(form) {
  const request = axios.post(
    `${API_URL}/api/inventario-producto/regularizar`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_INVENTORY_PRODUCT, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_INVENTORY_PRODUCT,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje)
        } else {
          dispatch({
            type: CRUD_INVENTORY_PRODUCT,
            payload: { loading: false, crud: false },
          })
          toast.error(response.data.mensaje)
        }

        dispatch({
          type: CRUD_INVENTORY_PRODUCT,
          payload: { loading: false, crud: false },
        })
      })
      .catch((error) => {
        dispatch({
          type: CRUD_INVENTORY_PRODUCT,
          payload: { loading: false, crud: false },
        })
        toast.error('Error inesperado. No se pudo procesar la solicitud')
        console.log(error)
      })
  }
}
