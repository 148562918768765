
export const ESTABLISHMENT_SECTOR = {
  COMMERCIAL: 1,
  AUTOMOTIVE: 2,
  HOTEL: 3,
  LOAN: 4,
}

export const ESTABLISHMENT_SECTORS = [
  {
    value: ESTABLISHMENT_SECTOR.COMMERCIAL,
    label: "Comercial"
  },
  {
    value: ESTABLISHMENT_SECTOR.AUTOMOTIVE,
    label: "Automotriz"
  },
  // {
  //   value: ESTABLISHMENT_SECTOR.HOTEL,
  //   label: "Hotelero"
  // },
  {
    value: ESTABLISHMENT_SECTOR.LOAN,
    label: "Cobranzas"
  }
]