import * as Actions from "../../actions/app"

const initialState = {

  loading_customers_with_credits: false,
  customers_with_credits: null,

  loading_customer_credits: false,
  customer_credits: null,

  loading_crud_customer_credit: false,
  crud_customer_credit: false,

  downloading_customer_account_statement: false,

  loading_customer_collections: false,
  customer_collections: null,
  total_customer_collections: 0,

  loading_recent_customer_collections: false,
  recent_customer_collections: null,

  loading_crud_customer_collection: false,
  crud_customer_collection: false,

}

const customerCreditsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CUSTOMERS_WITH_CREDITS: {
      return {
        ...state,
        loading_customers_with_credits: action.payload.loading,
        customers_with_credits: action.payload.data ?? state.customers_with_credits,
      }
    }

    case Actions.GET_CUSTOMER_CREDITS: {
      return {
        ...state,
        loading_customer_credits: action.payload.loading,
        customer_credits: action.payload.data,
      }
    }

    case Actions.CRUD_CUSTOMER_CREDIT: {
      return {
        ...state,
        loading_crud_customer_credit: action.payload.loading,
        crud_customer_credit: action.payload.crud,
      }
    }

    case Actions.DOWNLOADING_CUSTOMER_ACCOUNT_STATEMENT: {
      return {
        ...state,
        downloading_customer_account_statement: action.payload,
      }
    }

    case Actions.GET_CUSTOMER_COLLECTIONS: {
      return {
        ...state,
        loading_customer_collections: action.payload.loading,
        customer_collections: action.payload.detalles ?? state.customer_collections,
        total_customer_collections: action.payload.totalRegistros ?? state.total_customer_collections,
      }
    }

    case Actions.CRUD_CUSTOMER_COLLECTION: {
      return {
        ...state,
        loading_crud_customer_collection: action.payload.loading,
        crud_customer_collection: action.payload.crud,
      }
    }

    case Actions.GET_RECENT_CUSTOMER_COLLECTIONS: {
      return {
        ...state,
        loading_recent_customer_collections: action.payload.loading,
        recent_customer_collections: action.payload.data,
      }
    }

    default: {
      return state
    }
  }
}
export default customerCreditsReducer
