import axios from 'axios';
import { toast } from 'react-toastify';
import * as Actions from 'components/auth/store/actions';
import { API_URL } from 'constants/index'

export const GET_STORES = '[STORES] GET STORES';

export const CRUD_STORE = '[STORES] CRUD STORE';

export const GET_PRODUCTS_STORE = '[STORES] GET PRODUCTS STORE';

export const GET_PRODUCT_STOCK_IN_STORES =
  '[STORES] GET PRODUCT STOCK IN STORES';

export function getStores() {
  const request = axios.get(`${API_URL}/api/almacen`);
  return (dispatch) => {
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        return dispatch({ type: GET_STORES, payload: response.data.detalles });
      })
      .catch((error) => {
        toast.error('Error al obtener los almacenes');
        console.log(error);
      });
  };
}

export function saveStore(form) {
  const request = axios.post(
    `${API_URL}/api/almacen/guardar`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_STORE, payload: { loading: true } });
    toast.info('Guardando almacen...', {
      toastId: 'saveStore',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_STORE,
            payload: { loading: false, crud: true },
          });
          toast.update('saveStore', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('saveStore', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_STORE,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        toast.update('saveStore', {
          render: 'Error inesperado. No se pudo guardar el almacen',
          type: 'error',
          autoClose: 5000,
        });
        dispatch({
          type: CRUD_STORE,
          payload: { loading: false, crud: false },
        });
        console.log(error);
      });
  };
}

export function deleteStore(form) {
  const request = axios.post(
    `${API_URL}/api/almacen/eliminar`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_STORE, payload: { loading: true } });
    toast.info('Eliminando almacen...', {
      toastId: 'deleteStore',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_STORE,
            payload: { loading: false, crud: true },
          });
          toast.update('deleteStore', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('deleteStore', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_STORE,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        toast.update('deleteStore', {
          render: 'Error inesperado. No se pudo eliminar el almacen',
          type: 'error',
          autoClose: 5000,
        });
        dispatch({
          type: CRUD_STORE,
          payload: { loading: false, crud: false },
        });
        console.log(error);
      });
  };
}

export function getProductsByStore({ page = 1, storeId = 0 }) {
  const request = axios.get(
    `${API_URL}/api/almacen-productos?page=${page}&idalmacen=${storeId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_PRODUCTS_STORE, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        dispatch({
          type: GET_PRODUCTS_STORE,
          payload: { loading: false, data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PRODUCTS_STORE,
          payload: { loading: false },
        });
        toast.error(
          'Error inesperado. No se pudo obtener los productos del almacen'
        );
        console.log(error);
      });
  };
}

export function getProductStockInStores({ productId = 0 }) {
  const request = axios.get(
    `${API_URL}/api/almacen-existencias-producto?idproducto=${productId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_PRODUCT_STOCK_IN_STORES, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        dispatch({
          type: GET_PRODUCT_STOCK_IN_STORES,
          payload: { loading: false, data: response.data.detalles },
        });

        setTimeout(() => {
          dispatch({
            type: GET_PRODUCT_STOCK_IN_STORES,
            payload: { loading: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: GET_PRODUCT_STOCK_IN_STORES,
          payload: { loading: false, data: null },
        });
        toast.error(
          'Error inesperado. No se pudo obtener las existencias del producto en los almacenes'
        );
        console.log(error);
      });
  };
}
