import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const CRUD_HEADER_TRANSFER = "[TRANSFERS] CRUD HEADER TRANSFER"

export const GET_TRANSFERS = "[TRANSFERS] GET TRANSFERS MADE"

export const GET_TRANSFERS_BY_STORE = "[TRANSFERS] GET TRANSFERS BY STORE"

export const REJECT_OR_ACCEPT_TRANSFER = "[TRANSFERS] REJECT OR ACCEPT TRASNFER"

export const CRUD_TRANSFER_ITEM = "[TRANSFERS] CRUD TRANSFER ITEM"

export const GET_TRANSFER_ITEMS = "[TRANSFERS] GET TRANSFER ITEMS"

export const FINISH_TRANSFER_DETAIL = "[TRANSFERS] FINISH TRANSFER DETAIL"

export function saveHeaderTransfer(form) {
  const request = axios.post(`${API_URL}/api/traslado/guardar-cabecera`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_HEADER_TRANSFER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_HEADER_TRANSFER, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_HEADER_TRANSFER, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_HEADER_TRANSFER, payload: { loading: false, crud: false, data: null } })
      toast.error('Error inesperado. No se pudo generar el traslado')
      console.log(error)
    })
  }
}

export function getTransfers({ userId = 0, state = 'realizados' }) {
  const request = axios.get(`${API_URL}/api/traslado?idusuario=${userId}&estado=${state}`)
  return (dispatch) => {
    dispatch({ type: GET_TRANSFERS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_TRANSFERS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_TRANSFERS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_TRANSFERS, payload: { loading: false, data: null } })
      toast.error('Error inesperado. No se pudo obtener los traslados realizados')
      console.log(error)
    })
  }
}

export function getTransfersByStore({ storeOriginId = 0, storeDestinationId = 0, transferState = 0 }) {
  const request = axios.get(
    `${API_URL}/api/traslados-almacen?idalmacen_origen=${storeOriginId}&idalmacen_destino=${storeDestinationId}&estado=${transferState}`
  )
  return (dispatch) => {
    dispatch({ type: GET_TRANSFERS_BY_STORE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_TRANSFERS_BY_STORE, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_TRANSFERS_BY_STORE, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_TRANSFERS_BY_STORE, payload: { loading: false, data: null } })
      toast.error('Error inesperado. No se pudo obtener los traslados del almacen')
      console.log(error)
    })
  }
}

export function acceptTransfer(form) {
  const request = axios.post(`${API_URL}/api/traslado/aceptar-traslado`, form)
  return (dispatch) => {
    dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: false, crud: false } })
      toast.error('Error inesperado. No se pudo aceptar el traslado')
      console.log(error)
    })
  }
}

export function rejectTransfer(form) {
  const request = axios.post(`${API_URL}/api/traslado/rechazar-traslado`, form)
  return (dispatch) => {
    dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: REJECT_OR_ACCEPT_TRANSFER, payload: { loading: false, crud: false } })
      toast.error('Error inesperado. No se pudo rechazar el traslado')
      console.log(error)
    })
  }
}

export function saveTransferItem(form) {
  const request = axios.post(`${API_URL}/api/traslado-detalle/guardar-item`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: false, crud: false } })
      toast.error('Error inesperado. No se pudo guardar el item')
      console.log(error)
    })
  }
}

export function getTransferItems({ transferId = 0 }) {
  const request = axios.get(`${API_URL}/api/traslado-detalle/items?idtraslado=${transferId}`)
  return (dispatch) => {
    dispatch({ type: GET_TRANSFER_ITEMS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_TRANSFER_ITEMS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_TRANSFER_ITEMS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_TRANSFER_ITEMS, payload: { loading: false, data: null } })
      toast.error('Error inesperado. No se pudo obtener los items del traslado')
      console.log(error)
    })
  }
}

export function deleteTransferItem(form) {
  const request = axios.post(`${API_URL}/api/traslado-detalle/eliminar-item`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_TRANSFER_ITEM, payload: { loading: false, crud: false } })
      toast.error('Error inesperado. No se pudo eliminar el item')
      console.log(error)
    })
  }
}

export function finishTransferDetail(form) {
  const request = axios.post(`${API_URL}/api/traslado-detalle/finalizar-items`, form)
  return (dispatch) => {
    dispatch({ type: FINISH_TRANSFER_DETAIL, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: FINISH_TRANSFER_DETAIL, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: FINISH_TRANSFER_DETAIL, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: FINISH_TRANSFER_DETAIL, payload: { loading: false, crud: false } })
      toast.error('Error inesperado. No se pudo finalizar el detalle del traslado')
      console.log(error)
    })
  }
}
