import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const GET_LOAN_OPENING_INCOMES = "[LOAN OPENING INCOMES] GET LOAN OPENING INCOMES"

export const CRUD_LOAN_OPENING_INCOME = "[LOAN OPENING INCOMES] CRUD LOAN OPENING INCOME"

export const GET_LOAN_OPENING_EXPENSES = "[LOAN OPENING EXPENSES] GET LOAN OPENING EXPENSES"

export const CRUD_LOAN_OPENING_EXPENSE = "[LOAN OPENING EXPENSES] CRUD LOAN OPENING EXPENSE"

export function getLoanOpeningIncomes({ page = 1, userId = 0 }) {
  const request = axios.get(
    `${API_URL}/api/caja-prestamo-ingreso?page=${page}&idusuario=${userId}`
  )
  return dispatch => {
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_LOAN_OPENING_INCOMES, payload: response.data })
    }).catch((error) => {
      toast.error("Error inesperado. No se pudo obtener los ingresos")
      console.log(error)
    })
  }
}

export function saveLoanOpeningIncome(form) {
  const request = axios.post(`${API_URL}/api/caja-prestamo-ingreso/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar el ingreso")
      console.log(error)
    })
  }
}

export function deleteLoanOpeningIncome(form) {
  const request = axios.post(`${API_URL}/api/caja-prestamo-ingreso/eliminar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: true } })
    toast.info('Anulando ingreso...', { toastId: 'deleteLoanOpeningIncome', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: 'deleteLoanOpeningIncome', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'deleteLoanOpeningIncome', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING_INCOME, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo anular el ingreso", { toastId: 'deleteLoanOpeningIncome' })
      console.log(error)
    })
  }
}

export function getLoanOpeningExpenses({ page = 1, userId = 0 }) {
  const request = axios.get(
    `${API_URL}/api/caja-prestamo-egreso?page=${page}&idusuario=${userId}`
  )
  return dispatch => {
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_LOAN_OPENING_EXPENSES, payload: response.data })
    }).catch((error) => {
      toast.error("Error inesperado. No se pudo obtener los egresos")
      console.log(error)
    })
  }
}

export function saveLoanOpeningExpense(form) {
  const request = axios.post(`${API_URL}/api/caja-prestamo-egreso/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar el egreso")
      console.log(error)
    })
  }
}

export function deleteLoanOpeningExpense(form) {
  const request = axios.post(`${API_URL}/api/caja-prestamo-egreso/eliminar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: true } })
    toast.info('Anulando egreso...', { toastId: 'deleteLoanOpeningExpense', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: 'deleteLoanOpeningExpense', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'deleteLoanOpeningExpense', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING_EXPENSE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo anular el ingreso", { toastId: 'deleteLoanOpeningExpense' })
      console.log(error)
    })
  }
}