import axios from "axios"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"
import { API_URL } from 'constants/index'

export const GET_ESTABLISHMENT = "[ESTABLISHMENT] GET ESTABLISHMENT"

export const CRUD_ESTABLISHMENT = "[ESTABLISHMENT] CRUD ESTABLISHMENT"

export const GET_BRANCHES = "[BRANCHES] GET BRANCHES"

export const CRUD_BRANCH = "[BRANCHES] CRUD BRANCH"

export const GET_OPTIONS_BY_BRANCH = "[OPTIONS] GET OPTIONS BY BRANCH"

export const CRUD_BRANCH_OPTION = "[OPTIONS] CRUD OPTIONS BRANCH"

export const GET_BANK_ACCOUNTS = "[BANK ACCOUNTS] GET BANK ACCOUNTS"

export const CRUD_BANK_ACCOUNT = "[BANK ACCOUNTS] CRUD BANK ACCOUNT"

export function getEstablishment() {
  const request = axios.get(`${API_URL}/api/establecimiento/datos`)
  return (dispatch) => {
    dispatch({ type: GET_ESTABLISHMENT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_ESTABLISHMENT, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_ESTABLISHMENT, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_ESTABLISHMENT, payload: { loading: false, data: null } })
      toast.error("No se pudo obtener los datos del establecimiento")
      console.log(error)
    })
  }
}

export function saveEstablishment(form) {
  const request = axios.post(`${API_URL}/api/establecimiento/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_ESTABLISHMENT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: CRUD_ESTABLISHMENT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_ESTABLISHMENT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_ESTABLISHMENT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_ESTABLISHMENT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getBranches() {
  const request = axios.get(`${API_URL}/api/sucursal/registros`)
  return (dispatch) => {
    dispatch({ type: GET_BRANCHES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_BRANCHES, payload: { loading: false, data: response.data.detalles } })
    })
  }
}

export function saveBranch(form) {
  const request = axios.post(`${API_URL}/api/sucursal/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BRANCH, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteBranch(form) {
  const request = axios.post(`${API_URL}/api/sucursal/eliminar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BRANCH, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_BRANCH, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getOptionsByBranch(branchId = 0) {
  const request = axios.get(`${API_URL}/api/opciones/obtener-por-sucursal/${branchId}`)
  return (dispatch) => {
    dispatch({ type: GET_OPTIONS_BY_BRANCH, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_OPTIONS_BY_BRANCH, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_OPTIONS_BY_BRANCH, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_OPTIONS_BY_BRANCH, payload: { loading: false, data: null } })
      toast.error("No se pudo obtener las opciones de la sucursal")
      console.log(error)
    })
  }
}

export function saveOptionIgvType(form) {
  const request = axios.post(`${API_URL}/api/opciones/guardar-tipo-igv`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function saveOptionLogo(form) {
  const request = axios.post(`${API_URL}/api/opciones/guardar-logo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_BRANCH_OPTION, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getBankAccounts() {
  const request = axios.get(`${API_URL}/api/gcuentasbancarias`)
  return (dispatch) => {
    dispatch({ type: GET_BANK_ACCOUNTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_BANK_ACCOUNTS, payload: { loading: false, data: response.data.detalles } })
      return
    })
  }
}

export function saveBankAccount(form) {
  const request = axios.post(`${API_URL}/api/rcuentabancaria`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteBankAccount(form) {
  const request = axios.post(`${API_URL}/api/ecuentabancaria`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_BANK_ACCOUNT, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}