import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const CRUD_INVENTORY_PHOTO = "[VEHICLE INVENTORY] CRUD INVENTORY PHOTO"

export const CRUD_INVENTORY_OBSERVATION = "[VEHICLE INVENTORY] CRUD INVENTORY OBSERVATION"

export const CRUD_INVENTORY_ACCESSORY = "[VEHICLE INVENTORY] CRUD INVENTORY ACCESSORY"

export const GET_ACCESORIES_INVENTORY = "[VEHICLE INVENTORY] GET ACCESSORIES INVENTORY"

export function saveInventoryPhoto(form) {
  const request = axios.post(`${API_URL}/api/rinventariofoto`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_INVENTORY_PHOTO, payload: { loading: true } })
    toast.info("Guardando foto en el inventario...", { toastId: "saveInventoryPhoto", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_INVENTORY_PHOTO, payload: { loading: false, crud: true } })
        toast.update("saveInventoryPhoto", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveInventoryPhoto", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_INVENTORY_PHOTO, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_INVENTORY_PHOTO, payload: { loading: false, crud: false } })
      toast.update("saveInventoryPhoto", { autoClose: 4000, type: "error", render: "Error al guardar foto en el inventario" })
      console.log(error)
    })
  }
}

export function saveInventoryObservation(form) {
  const request = axios.post(`${API_URL}/api/rinventarioobservacion`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_INVENTORY_OBSERVATION, payload: { loading: true } })
    toast.info("Guardando observaciones en el inventario...", { toastId: "saveInventoryObservation", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_INVENTORY_OBSERVATION, payload: { loading: false, crud: true } })
        toast.update("saveInventoryObservation", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveInventoryObservation", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_INVENTORY_OBSERVATION, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_INVENTORY_OBSERVATION, payload: { loading: false, crud: false } })
      toast.update("saveInventoryObservation", { autoClose: 4000, type: "error", render: "Error al guardar la observacion del inventario" })
      console.log(error)
    })
  }
}

export function saveInventoryAccessory(form) {
  const request = axios.post(`${API_URL}/api/rinventarioaccesorio`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: true } })
    toast.info("Guardando accesorio en el inventario...", { toastId: "saveInventoryAccessory", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: false, crud: true } })
        toast.update("saveInventoryAccessory", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveInventoryAccessory", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: false, crud: false } })
      toast.update("saveInventoryAccessory", { autoClose: 4000, type: "error", render: "Error al guardar accesorio en el inventario" })
      console.log(error)
    })
  }
}

export function deleteInventoryAccessory(form) {
  const request = axios.post(`${API_URL}/api/einventarioaccesorio`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: true } })
    toast.info("Eliminando accesorio del inventario...", { toastId: "deleteInventoryAccessory", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: false, crud: true } })
        toast.update("deleteInventoryAccessory", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteInventoryAccessory", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_INVENTORY_ACCESSORY, payload: { loading: false, crud: false } })
      toast.update("deleteInventoryAccessory", { autoClose: 4000, type: "error", render: "Error al eliminar accesorio del inventario" })
      console.log(error)
    })
  }
}

export function getAccessoriesByServiceOrder(orderId, useLoading = false) {
  const request = axios.get(`${API_URL}/api/ginventarioaccesoriosorden/${orderId}`)
  return (dispatch) => {
    if (useLoading) {
      dispatch({ type: GET_ACCESORIES_INVENTORY, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_ACCESORIES_INVENTORY, payload: { loading: false, data: response.data.detalles } })
      } else {
        dispatch({ type: GET_ACCESORIES_INVENTORY, payload: { loading: false, data: null } })
      }
      return setTimeout(() => {
        dispatch({ type: GET_ACCESORIES_INVENTORY, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_ACCESORIES_INVENTORY, payload: { loading: false, data: null } })
      toast.error("Error al obtener accesorios del inventario")
      console.log(error)
    })
  }
}